import React, { useEffect, useState } from 'react'
import { Layout } from 'core/components'
import { AAnimate } from 'shared/components/common'
import { TrackingLayout } from 'modules/parcels/pages'
import { useQueryParamString } from 'react-use-query-param-string'

type RastreoProps = PageProps

const Rastreo: React.FC<RastreoProps> = ({ pageContext: { locale }, location }) => {
  const [mounted, setMounted] = useState(false)
  const [code, setCode] = useState('')

  useEffect(() => {
    setTimeout(() => {
      setMounted(true)
    }, 0)
  }, [])

  const [searchCode] = useQueryParamString('code', '')

  useEffect(() => {
    if (searchCode) {
      setCode(searchCode)
    } else if (location.state && location.state.code) {
      setCode(location.state.code)
    }
  }, [searchCode, location.state])

  return (
    <Layout locale={locale}>
      <AAnimate type="fadeIn" in={mounted}>
        <TrackingLayout code={code} />
      </AAnimate>
    </Layout>
  )
}

export default Rastreo
